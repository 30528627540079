import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon, { IconProps } from "domains/ui/components/Icon";

interface CarouselNavButtonProps
  extends Omit<ButtonProps, "direction" | "variant"> {
  variant?: "primary" | "secondary";
  direction: "prev" | "next";
}

export function CarouselNavButton({
  variant = "primary",
  direction,
  ...props
}: CarouselNavButtonProps) {
  const customProps = (() => {
    const customProps: {
      buttonProps: ButtonProps;
      iconId: IconProps["id"];
    } = { buttonProps: {}, iconId: "Ui/ChevronLeft" };

    switch (direction) {
      case "prev":
        customProps.buttonProps.pr = "2px";
        customProps.iconId = "Ui/ChevronLeft";
        break;
      case "next":
        customProps.buttonProps.pl = "2px";
        customProps.iconId = "Ui/ChevronRight";
        break;
    }

    switch (variant) {
      case "primary":
        customProps.buttonProps.bgColor = "background.500";
        customProps.buttonProps._hover = { bgColor: "backgroundSecondary.500" };
        customProps.buttonProps._active = {
          bgColor: "backgroundSecondary.800",
        };
        break;
      case "secondary":
        customProps.buttonProps.bgColor = "whiteAlpha.50";
        customProps.buttonProps._hover = { bgColor: "whiteAlpha.100" };
        customProps.buttonProps._active = {
          bgColor: "whiteAlpha.200",
        };
        break;
    }
    return customProps;
  })();

  return (
    <Button
      variant="unstyled"
      borderWidth={1}
      borderColor="border.500"
      borderRadius="full"
      w="40px"
      h="40px"
      mb="40px"
      pb="2px"
      {...customProps.buttonProps}
      {...props}
    >
      <Icon id={customProps.iconId} />
    </Button>
  );
}
