import { useWindowSize } from "domains/ui/hooks/useWindowSize";

import { Box, HStack, Link, Text } from "@chakra-ui/react";

export const FOOTER_HEIGHT = 50;

export default function Footer() {
  const { width: windowWidth } = useWindowSize();

  return (
    <>
      <Box flexGrow={1} />
      <HStack
        pos="relative"
        align="center"
        justify="space-between"
        w="100%"
        h={`${FOOTER_HEIGHT}px`}
        px={4}
        borderTopWidth="1px"
        bgColor="black.500"
      >
        <iframe
          style={{
            colorScheme: "none",
            borderRadius: "8px",
          }}
          src="https://status.scenario.com/badge?theme=dark"
          width={(windowWidth ?? 999) < 550 ? "26" : "250"}
          height="30"
          frameBorder="0"
          scrolling="no"
        />
        <HStack align="center" justify="center" pr={"140px"} spacing={4}>
          <SocialLink
            href="https://www.youtube.com/@scenario_gg"
            label="YouTube"
          />
          <SocialLink href="https://twitter.com/Scenario_gg" label="X" />
          <SocialLink
            href="https://discord.gg/scenario-ai-generated-game-assets-1042738264639012864"
            label="Discord"
          />
          <SocialLink
            href="https://www.linkedin.com/company/scenario-com/"
            label="LinkedIn"
          />
        </HStack>
      </HStack>
    </>
  );
}

function SocialLink({ href, label }: { href: string; label: string }) {
  return (
    <Link href={href} isExternal>
      <Text size="body.md">{label}</Text>
    </Link>
  );
}
